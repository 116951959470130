"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.hitori_generate_board = exports.hitori_check_rule4 = exports.hitori_check_rule3 = exports.hitori_check_rule2 = void 0;
const utils_1 = require("@src/shared/utils");
const CELL_PAINTED = 1 << 1;
const CELL_SHOULD_BE_PAINTED = 1 << 2;
const CELL_TAG1 = 1 << 3;
const CELL_TAG2 = 1 << 4;
const CELL_ERROR = 1 << 5;
const debug = false;
const game = {};
const rand = function () {
    return (0, utils_1.randomInt)(0, 2147483647);
};
const hitori_check_rule2 = (hitori) => {
    let success = true;
    let iter = {};
    /* Check the squares immediately next to the current one; if they're painted, the rule fails. */
    /* Проверьте квадраты непосредственно рядом с текущим; если они закрашены, правило не выполняется. */
    for (iter.x = 0; iter.x < hitori['board_size']; iter.x++) {
        for (iter.y = 0; iter.y < hitori['board_size']; iter.y++) {
            if (hitori['board'][iter.x][iter.y].status & CELL_PAINTED &&
                ((iter.x < hitori['board_size'] - 1 && hitori['board'][iter.x + 1][iter.y].status & CELL_PAINTED) ||
                    (iter.y < hitori['board_size'] - 1 && hitori['board'][iter.x][iter.y + 1].status & CELL_PAINTED) ||
                    (iter.x > 0 && hitori['board'][iter.x - 1][iter.y].status & CELL_PAINTED) ||
                    (iter.y > 0 && hitori['board'][iter.x][iter.y - 1].status & CELL_PAINTED))) {
                if (debug)
                    console.log("Rule 2 failed");
                /* Mark the cell as being erroneous and continue to the other cells so that they also get marked */
                hitori['board'][iter.x][iter.y].status |= CELL_ERROR;
                success = false;
            }
            else {
                /* Clear any error in the cell */
                hitori['board'][iter.x][iter.y].status &= ~CELL_ERROR;
            }
        }
    }
    return success;
};
exports.hitori_check_rule2 = hitori_check_rule2;
const hitori_check_rule3 = (hitori) => {
    let iter = {};
    let first = null;
    let success;
    let queue = [];
    /* Pick an unpainted cell. */
    for (iter.x = 0; first == null && iter.x < hitori['board_size']; iter.x++) {
        for (iter.y = 0; !first && iter.y < hitori['board_size']; iter.y++) {
            if ((hitori['board'][iter.x][iter.y].status & CELL_PAINTED) == 0) {
                if (iter.x == 0) {
                    if (iter.y == 0) {
                        if ((hitori['board'][iter.x][iter.y + 1].status & CELL_PAINTED) &&
                            (hitori['board'][iter.x + 1][iter.y].status & CELL_PAINTED)) {
                            continue;
                        }
                    }
                    else {
                        if (((iter.y + 1) < hitori['board_size'] && hitori['board'][iter.x][iter.y + 1].status & CELL_PAINTED) &&
                            (hitori['board'][iter.x + 1][iter.y].status & CELL_PAINTED) &&
                            (hitori['board'][iter.x][iter.y - 1].status & CELL_PAINTED)) {
                            continue;
                        }
                    }
                }
                first = { x: iter.x, y: iter.y };
            }
        }
    }
    if (first == null)
        return false;
    console.log({ first });
    /* Allocate a board of booleans to keep track of which cells we can reach */
    let reached = new Array(hitori['board_size']).fill(undefined);
    for (iter.x = 0; iter.x < hitori['board_size']; iter.x++)
        reached[iter.x] = new Array(hitori['board_size']).fill(false);
    /* Use a basic floodfill algorithm to traverse the board */
    queue.push(first);
    while (queue.length) {
        iter = queue.shift();
        if (reached[iter.x][iter.y] == false && (hitori['board'][iter.x][iter.y].status & CELL_PAINTED) == 0) {
            /* Mark the cell as having been reached */
            reached[iter.x][iter.y] = true;
            if (iter.x > 0) {
                queue.push({ x: iter.x - 1, y: iter.y });
            }
            if (iter.y > 0) {
                queue.push({ x: iter.x, y: iter.y - 1 });
            }
            if (iter.x < hitori['board_size'] - 1) {
                queue.push({ x: iter.x + 1, y: iter.y });
            }
            if (iter.y < hitori['board_size'] - 1) {
                queue.push({ x: iter.x, y: iter.y + 1 });
            }
        }
    }
    console.log({ reached });
    /* Check if there's an unpainted cell we haven't reached */
    /* Проверьте, есть ли неокрашенная ячейка, до которой мы еще не добрались */
    success = true;
    for (iter.x = 0; iter.x < hitori['board_size']; iter.x++) {
        for (iter.y = 0; iter.y < hitori['board_size']; iter.y++) {
            if (reached[iter.x][iter.y] == false && (hitori['board'][iter.x][iter.y].status & CELL_PAINTED) == 0) {
                success = false;
                /* Highlight its neighbours as erroneous */
                if (iter.x > 0 && (hitori['board'][iter.x - 1][iter.y].status & CELL_PAINTED))
                    hitori['board'][iter.x - 1][iter.y].status |= CELL_ERROR;
                if (iter.y > 0 && (hitori['board'][iter.x][iter.y - 1].status & CELL_PAINTED))
                    hitori['board'][iter.x][iter.y - 1].status |= CELL_ERROR;
                if (iter.x < hitori['board_size'] - 1 && (hitori['board'][iter.x + 1][iter.y].status & CELL_PAINTED))
                    hitori['board'][iter.x + 1][iter.y].status |= CELL_ERROR;
                if (iter.y < hitori['board_size'] - 1 && (hitori['board'][iter.x][iter.y + 1].status & CELL_PAINTED))
                    hitori['board'][iter.x][iter.y + 1].status |= CELL_ERROR;
            }
        }
    }
    /* Free everything */
    // for (iter.x = 0; iter.x < hitori['board_size']; iter.x++)
    // 	g_free (reached[iter.x]);
    // g_free (reached);
    if (debug)
        console.log(success ? "Rule 3 OK" : "Rule 3 failed");
    return success;
};
exports.hitori_check_rule3 = hitori_check_rule3;
const hitori_check_rule4 = function (hitory) {
    let success = true;
    let transBoard = {};
    hitory.board.forEach((row, i) => {
        const vals = row.filter((item) => item.mark != 'black').map((item) => item.num);
        row.forEach((item, y) => {
            transBoard[y] = transBoard[y] || [];
            transBoard[y].push(item);
        });
        if (vals.some((v, i) => vals.indexOf(v) < i)) {
            success = false;
        }
    });
    Object.values(transBoard).forEach((col) => {
        const vals = col.filter((item) => item.mark != 'black').map((item) => item.num);
        if (vals.some((v, i) => vals.indexOf(v) < i)) {
            success = false;
        }
    });
    return success;
};
exports.hitori_check_rule4 = hitori_check_rule4;
const hitori_generate_board = (hitori, size, seed) => {
    let total;
    let old_total;
    let iter = {};
    if (seed == 0) {
        seed = Math.floor(Date.now() / 1000);
    }
    if (debug) {
        console.log(seed);
    }
    hitori['board_size'] = size;
    let accum = new Array(size + 2).fill(undefined);
    const horiz_accum = new Array(size).fill(undefined);
    for (iter.x = 0; iter.x < hitori['board_size']; iter.x++)
        horiz_accum[iter.x] = new Array(size + 2).fill(undefined);
    hitori['board'] = new Array(size).fill(undefined);
    for (let i = 0; i < hitori['board_size']; i++)
        hitori['board'][i] = new Array(size).fill(undefined).map(() => ({}));
    total = rand() % 5 + 20;
    for (let i = 0; i < total; i++) {
        while (true) {
            iter.x = rand() % hitori['board_size'];
            iter.y = rand() % hitori['board_size'];
            if ((iter.y < 1 || (hitori['board'][iter.x][iter.y - 1].status & CELL_PAINTED) == 0) &&
                (iter.y + 1 >= hitori['board_size'] || (hitori['board'][iter.x][iter.y + 1].status & CELL_PAINTED) == 0) &&
                (iter.x < 1 || (hitori['board'][iter.x - 1][iter.y].status & CELL_PAINTED) == 0) &&
                (iter.x + 1 >= hitori['board_size'] || (hitori['board'][iter.x + 1][iter.y].status & CELL_PAINTED) == 0))
                break;
        }
        hitori['board'][iter.x][iter.y].status |= (CELL_PAINTED | CELL_SHOULD_BE_PAINTED);
    }
    /* Проверьте, чтобы нарисованные квадраты все не перепутали */
    if ((0, exports.hitori_check_rule2)(hitori) == false || (0, exports.hitori_check_rule3)(hitori) == false) {
        // accum.splice(0, accum.length)
        // for (iter.x = 0; iter.x < hitori['board_size']; iter.x++)
        //     horiz_accum[iter.x].splice(horiz_accum[iter.x], horiz_accum[iter.x].length)
        // horiz_accum.splice(0, horiz_accum.length)
        return (0, exports.hitori_generate_board)(hitori, hitori['board_size'], seed + 1);
    }
    for (iter.x = 0; iter.x < hitori['board_size']; iter.x++) {
        /* Reset the vertical accumulator */
        for (iter.y = 1; iter.y < hitori['board_size'] + 2; iter.y++)
            accum[iter.y] = false;
        let i = 0;
        accum[0] = true;
        total = hitori['board_size'] + 1;
        old_total = total;
        for (iter.y = 0; iter.y < hitori['board_size']; iter.y++) {
            if ((hitori['board'][iter.x][iter.y].status & CELL_PAINTED) == 0) {
                while (accum[i] == true || horiz_accum[iter.y][i] == true) {
                    if (horiz_accum[iter.y][i] == true && accum[i] == false)
                        total--;
                    if (total < 1) {
                        // accum.splice(0, accum.length)
                        // for (iter.x = 0; iter.x < hitori['board_size']; iter.x++)
                        //     horiz_accum[iter.x].splice(horiz_accum[iter.x], horiz_accum[iter.x].length)
                        // horiz_accum.splice(0, horiz_accum.length)
                        return (0, exports.hitori_generate_board)(hitori, hitori['board_size'], seed + 1); /* Мы облажались */
                    }
                    i = rand() % (hitori['board_size'] + 1);
                }
                accum[i] = true;
                horiz_accum[iter.y][i] = true;
                total = old_total;
                total--;
                hitori['board'][iter.x][iter.y].num = i;
            }
        }
    }
    // console.log(JSON.stringify(hitori['board']))
    // accum.splice(0, accum.length)
    // for (iter.x = 0; iter.x < hitori['board_size']; iter.x++)
    //     horiz_accum[iter.x].splice(horiz_accum[iter.x], horiz_accum[iter.x].length)
    // horiz_accum.splice(0, horiz_accum.length)
    /* Fill in the painted squares, making sure they duplicate a number
         * already in the column/row. */
    /* Заполните закрашенные квадраты, убедившись, что они дублируют число
*, уже находящееся в столбце/строке. */
    for (iter.x = 0; iter.x < hitori['board_size']; iter.x++) {
        for (iter.y = 0; iter.y < hitori['board_size']; iter.y++) {
            if (hitori['board'][iter.x][iter.y].status & CELL_PAINTED) {
                do {
                    let i = rand() % hitori['board_size'];
                    if (iter.x > iter.y)
                        total = hitori['board'][iter.x][i].num; /* Take a number from the row */
                    else
                        total = hitori['board'][i][iter.y].num; /* Take a number from the column */
                } while (!total);
                hitori['board'][iter.x][iter.y].num = total;
                hitori['board'][iter.x][iter.y].status &= (~CELL_PAINTED & ~CELL_ERROR);
            }
        }
    }
    return hitori;
};
exports.hitori_generate_board = hitori_generate_board;
